<template>
  <div>
    <Toolbar
      :handle-edit="seminarDialogControl"
      :handle-delete="del"
      :handle-copy="copy"
      :enable-delete="isDeleteEnabled"
      :enable-copy="hasRole('ROLE_CREATE_SEMINAR')"
      :enable-edit="isEditEnabled"
    >
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item['@id']}` }}</v-toolbar-title>
      </template>
    </Toolbar>
    <v-card
      class="mx-4"
      :loading="isLoading"
    >
      <v-card
        flat
        class="pt-0"
        v-if="seminar"
      >
        <v-card-title class="text-h5">
          <div>{{ seminar.name }}</div>
          <div v-if="timeSlot">&nbsp;am {{ formatDateTime(timeSlot.start, 'D.M.Y') }}</div>
          <div class="ml-auto">#{{ seminar.number }}</div>
        </v-card-title>
        <v-card-subtitle class="d-flex justify-start align-center mt-1">
          <StatusChip
            :is-positive="item.seminarConfig.enabled"
            type-positive="success"
            type-negative="danger"
            icon-positive="mdi-check-circle"
            icon-negative="mdi-close-circle"
            label-positive="Aktiv"
            label-negative="Inaktiv"
          />

          <template v-if="item.seminarConfig.cancelled">
            <StatusChip
              :is-positive="item.seminarConfig.cancelled"
              icon-positive="mdi-close"
              type-positive="warning"
              label-positive="Storniert"
              label-negative="nicht Storniert"
            />
          </template>
          <template v-else>
            <StatusChip
              :is-positive="item.seminarConfig.confirmed"
              :label-positive="$t('Confirmed')"
              :label-negative="$t('NotConfirmed')"
            />
            <StatusChip
              :is-positive="item.seminarConfig.completed"
              type-negative="notice"
              :label-positive="$t('Completed')"
              :label-negative="$t('NotCompleted')"
            />
          </template>

          <StatusChip
            :is-positive="!item.seminarConfig.noOnline"
            type-positive="primary"
            type-negative="notice"
            icon-positive="mdi-broadcast"
            icon-negative="mdi-broadcast-off"
            label-positive="Onlineseminar (BBB)"
            label-negative="Kein Onlineseminar (BBB)"
          />

          <StatusChip
            :is-positive="item.seminarConfig.autoInvoice"
            type-positive="primary"
            type-negative="notice"
            icon-positive="mdi-cash-multiple"
            icon-negative="mdi-close-circle"
            label-positive="Auto. Rechnungsversand aktiviert."
            label-negative="Auto. Rechnungsversand deaktiviert."
            :click-function="item.seminarConfig.autoInvoice ? this.deactivateAutoInvoice : null"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card
                class="fill-height"
                outlined
                tile
              >
                <v-card-text>
                  <h3 class="mb-2">{{ $t('Topics') }}:</h3>
                  <v-chip
                    class="mr-2 mb-2"
                    outlined
                    v-for="topic in topics"
                    :key="topic['@id']"
                  >
                    {{ topic.name }}
                  </v-chip>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-card
                class="pa-1 fill-height"
                outlined
                tile
              >
                <v-card-text>
                  <div v-if="timeSlot">
                    <h3>{{ $t('Appointment') }}:</h3>
                    <div>
                      <strong>{{ $t('Start') }}:</strong>
                      {{ formatDateTime(timeSlot.start, 'L LT') }}
                    </div>
                    <div>
                      <strong>{{ $t('End') }}:</strong>
                      {{ formatDateTime(timeSlot.end, 'L LT') }}
                    </div>
                  </div>
                  <div class="mt-4">
                    <h3>{{ $t('Speaker') }}:</h3>
                    <template v-if="item.speaker">
                      <template v-if="speakerProfile">
                        {{ speakerProfile.salutation }}
                        {{ speakerProfile.title }}
                        {{ speakerProfile.firstName }}
                        {{ speakerProfile.lastName }}
                      </template>
                      <template v-else-if="speaker && speaker.email">
                        {{ speaker.email }}
                      </template>
                    </template>
                    <div v-if="item.coSpeaker">
                      <h4>{{ $t('CoSpeaker') }}:</h4>
                      <div v-if="item.coSpeaker">
                        <template v-if="coSpeakerProfile">
                          {{ coSpeakerProfile.salutation }}
                          {{ coSpeakerProfile.title }}
                          {{ coSpeakerProfile.firstName }}
                          {{ coSpeakerProfile.lastName }}
                        </template>
                        <template v-else-if="coSpeaker">
                          {{ coSpeaker.email }}
                        </template>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="7">
              <v-card
                class="pa-1 fill-height"
                outlined
                tile
              >
                <v-card-title>{{ $t('ParticipantInformation') }}:</v-card-title>
                <v-card-text>
                  <div class="d-flex flex-row justify-space-between">
                    <div>
                      <strong>{{ $t('fee') }}:</strong> {{ seminar.fee }}€
                    </div>
                    <div>
                      <strong>{{ $t('maxParticipants') }}:</strong> {{ seminar.maxParticipants }}
                    </div>
                    <div>
                      <strong>{{ $t(tenantHoursLabel) }}:</strong> {{ seminar.teachingUnits }}
                    </div>
                  </div>
                  <div class="mt-4 d-flex flex-row justify-center">
                    <ContentHtmlDialog
                      v-if="seminar.seminarContent"
                      :title="$t('seminarContent')"
                      :content-html="seminar.seminarContent"
                      :open-label="$t('seminarContent')"
                      open-icon="mdi-magnify-plus-cursor"
                    />
                    <ContentHtmlDialog
                      class="ml-4"
                      :title="$t('additionalConfirmationText')"
                      :content-html="seminar.additionalConfirmationText ? seminar.additionalConfirmationText : ''
                        "
                      :open-label="$t('additionalConfirmationTextShorten')"
                      open-icon="mdi-magnify-plus-cursor"
                    />

                    <template v-if="hasRole('ROLE_UPDATE_SEMINAR')">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            text
                            outlined
                            style="text-transform: none"
                            class="ml-4"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ $t('filesToDownload') }}
                            <v-icon
                              right
                              dark
                            >mdi-file-download</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-if="!seminar.seminarConfig.completed"
                            @click="previewParticipantConfirmation(seminar)"
                          >
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-find' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('PreviewParticipantConfirmation') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="signatureListDialogControl(false)">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-signature-freehand' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateSignatureList') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            v-if="!isInhouse"
                            @click="generateParticipantList()"
                          >
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-clipboard-list-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantList') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="generateParticipantListCsv()">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-delimited-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantListCsv') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="generateParticipantNameCardListCsv()">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-delimited-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantNameCardListCsv') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="generateParticipantEmailListCsv()">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-delimited-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantEmailListCsv') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            v-if="isInhouse || seminar.seminarConfig.noOnline"
                            :disabled="!seminar.seminarConfig.enabled"
                            @click="participantConfirmationsPackage(seminar)"
                          >
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-package-down' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('DownloadAllParticipantConfirmationsPackage') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <SignatureListDialog
                        v-model="showSignatureListDialogForm"
                        @close="signatureListDialogControl(true)"
                        :show-handle="showSignatureListDialogForm"
                        :seminar-item="seminar"
                      />
                    </template>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="hasRole('ROLE_UPDATE_SEMINAR')">
            <v-col cols="12">
              <v-card
                outlined
                tile
              >
                <v-card-title>Aktionen</v-card-title>
                <v-card-text v-if="!seminar.seminarConfig.cancelled">
                  <div
                    v-if="!seminar.seminarConfig.enabled"
                    class="mb-2"
                  >
                    <v-btn
                      color="success"
                      class="mr-2"
                      @click="confirmSetEnable"
                    >
                      {{ $t('SetEnable') }}
                    </v-btn>
                  </div>
                  <div
                    v-if="seminar.seminarConfig.enabled && !seminar.seminarConfig.cancelled && !seminar.seminarConfig.confirmed"
                    class="mb-2"
                  >
                    <v-btn
                      color="error"
                      class="mr-2"
                      @click="confirmSetDisable"
                    >
                      {{ $t('SetDisable') }}
                    </v-btn>
                  </div>

                  <div
                    v-if="seminar.seminarConfig.enabled && !seminar.seminarConfig.cancelled && !seminar.seminarConfig.confirmed"
                    class="mb-2"
                  >
                    <v-btn
                      :disabled="!seminar.seminarConfig.enabled"
                      color="success"
                      class="mr-2"
                      @click="confirmSetConfirmed"
                    >
                      {{ $t('SetConfirmed') }}
                    </v-btn>
                    <v-btn
                      :disabled="!seminar.seminarConfig.enabled"
                      color="warning"
                      class="mr-2"
                      @click="confirmSetCancelled"
                    >
                      {{ $t('SetCancelled') }}
                    </v-btn>
                  </div>

                  <div
                    v-if="seminar.seminarConfig.noOnline === false && seminar.seminarConfig.seminarHappens && !seminar.seminarConfig.completed"
                    class="mb-2"
                  >
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click.prevent="confirmSendAllInvitations"
                      :disabled="!meeting && !seminar.externalMeeting && seminar.noOnline === false"
                    >
                      {{ $t('InviteAllParticipants') }}
                      <v-icon right>{{ 'mdi-account-multiple-plus' }}</v-icon>
                    </v-btn>

                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click.prevent="confirmSendSpeakerInvitations"
                      :disabled="!meeting && !seminar.externalMeeting && seminar.seminarConfig.noOnline === false"
                    >
                      {{ $t('InviteAllSpeakers') }}
                      <v-icon right>{{ 'mdi-account-multiple-plus' }}</v-icon>
                    </v-btn>

                    <span
                      v-if="!meeting && !seminar.externalMeeting && seminar.seminarConfig.noOnline === false"
                      class="ml-2 font-weight-bold"
                    >
                      Kein BBB Meeting verfügbar.
                      <v-btn
                        color="primary"
                        style="height: auto"
                        class="pa-0 text-none"
                        plain
                        tile
                        @click.prevent="$vuetify.goTo('#create-meeting')"
                      >Hier verwalten</v-btn>
                    </span>
                  </div>

                  <div class="mb-2">
                    <v-btn
                      v-if="!seminar.seminarConfig.completed && seminar.seminarConfig.seminarHappens"
                      color="success"
                      class="mr-2"
                      @click="confirmSetCompleted"
                    >
                      {{ $t('SetCompleted') }}
                    </v-btn>
                    <v-btn
                      v-if="isPublicBookable && this.seminar.seminarConfig.autoInvoice"
                      color="success"
                      @click="confirmSendInvoices"
                      class="mr-2"
                    >
                      {{ $t('SendInvoices') }}
                    </v-btn>
                  </div>
                  <div
                    v-if="seminar.seminarConfig.completed && seminar.seminarConfig.seminarHappens"
                    class="mb-2"
                  >
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="confirmSendSpeakerCertificates"
                      :disabled="seminar.speakerCertificationSent"
                    >
                      {{ $t('SendSpeakerCertificates') }}
                    </v-btn>
                    <v-btn
                      :disabled="isNoOnlineSeminar || seminar.seminarConfig.participantCertificationsSent"
                      color="primary"
                      class="mr-2"
                      @click="confirmSendParticipantConfirmations"
                    >
                      {{ $t('SendParticipantConfirmations') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="confirmGenerateInvoiceParticipant"
                      v-if="seminar.seminarConfig.billingPerParticipant && !seminar.invoice && this.isInhouse && seminar.offer"
                    >
                      {{ $t("generateInvoiceForNumberOfParticipants") }}
                    </v-btn>
                  </div>
                  <div
                    v-if="!seminar.seminarConfig.autoInvoice && seminar.seminarConfig.confirmed && !seminar.seminarConfig.billingPerParticipant"
                    class="mb-2"
                  >
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="invoiceApprovalDialogControl(false)"
                    >
                      {{ $t('InvoiceApproval') }}
                    </v-btn>
                    <InvoiceApprovalDialog
                      v-model="showInvoiceApprovalDialog"
                      @close="invoiceApprovalDialogControl(true)"
                      :title="$t('Rechnungsversand')"
                      :show-handle="showInvoiceApprovalDialog"
                      :seminar="this.seminar"
                    />
                  </div>
                </v-card-text>
                <v-card-text v-else>
                  <strong>Seminar wurde storniert</strong>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card
                outlined
                tile
              >
                <v-card-text>
                  <ParticipantListAndAdd
                    ref="participantListAndAdd"
                    :default-item-props="{ seminar: seminar['@id'] }"
                    :seminarHappens="seminar.seminarConfig.seminarHappens"
                    :is-public-bookable="isPublicBookable"
                    :seminar="seminar"
                    :participants="seminar.participants"
                    :is-no-online="isNoOnlineSeminar"
                    :locked="!seminar.seminarConfig.enabled"
                    object-type="seminar"
                    @updateParticipants="updateParticipantsHandler"
                  />
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <template v-if="seminar.seminarConfig.noOnline === false">
                <template v-if="seminar.seminarConfig.completed === false">
                  <v-card
                    outlined
                    tile
                    class="fill-height"
                    id="create-meeting"
                  >
                    <v-overlay
                      :absolute="true"
                      :value="seminar.seminarConfig.confirmed === false || seminar.seminarConfig.cancelled"
                    />
                    <v-card-title>{{ $t('OnlineMeetingRoom') }}</v-card-title>
                    <v-card-text v-if="meeting && !seminar.externalMeeting">
                      <p v-if="seminar.seminarConfig.confirmed === false || seminar.seminarConfig.cancelled">
                        Das Seminar muss
                        <template v-if="!seminar.seminarConfig.enabled"> aktiviert und </template>
                        bestätigt sein, damit der Seminar-Raum in Big Blue Button verwaltet werden kann.
                      </p>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <h4>Meeting Status:</h4>
                          <span
                            v-if="meeting.running"
                            style="color: green"
                          >
                            Raum ist aktiv.
                            {{ meeting.moderatorCount }} Moderator(en) &amp; {{ meeting.participantCount }} Teilnehmer
                          </span>
                          <span
                            v-if="!meeting.running"
                            style="color: red"
                          >Raum ist nicht aktiv.</span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                @click="refreshMeetingRoom"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>{{ 'mdi-refresh' }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('RefreshRoomStatus') }}</span>
                          </v-tooltip>
                          <v-btn
                            color="primary"
                            small
                            outlined
                            :disabled="!seminar.seminarConfig.seminarHappens || meeting.running"
                            v-if="!meeting.running"
                            @click="startMeeting()"
                          >
                            {{ $t('StartMeeting') }}
                            <v-icon right>{{ 'mdi-play-circle-outline' }}</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-btn
                            color="primary"
                            small
                            outlined
                            @click="meetingModeratorDialogControl()"
                            :disabled="!seminar.seminarConfig.seminarHappens || !meeting.running"
                          >
                            {{ $t('JoinAsModerator') }}
                            <v-icon right>{{ 'mdi-account-plus' }}</v-icon>
                          </v-btn>
                          <ModeratorDialog
                            v-model="showMeetingModeratorDialog"
                            :show-handle="showMeetingModeratorDialog"
                            :seminar-id="seminar['@id']"
                            :meeting="meeting"
                            @close="showMeetingModeratorDialog = false"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text v-else>
                      <template v-if="
                        seminar.seminarConfig.enabled &&
                        !seminar.seminarConfig.cancelled &&
                        !seminar.meeting &&
                        seminar.seminarConfig.confirmed &&
                        !seminar.externalMeeting
                      ">
                        <v-btn
                          color="success"
                          class="mr-2"
                          @click="blankUpdate"
                        >
                          {{ $t('CreateMeeting') }}
                        </v-btn>
                      </template>
                      <template v-else>
                        <template v-if="seminar.externalMeeting">
                          <template
                            v-if="seminar.seminarConfig.enabled && !seminar.seminarConfig.cancelled && seminar.seminarConfig.confirmed"
                          >
                            Das Seminar findet über einen externen Online Seminar-Raum statt. <br />
                            <strong>URL</strong>:
                            <a :href="seminar.externalMeeting.joinUrl">{{
                              seminar.externalMeeting.joinUrl
                              }}</a><br />
                            <strong>Notiz</strong>: {{ seminar.externalMeeting.title }}<br />
                            <strong>Passwort</strong>: {{ seminar.externalMeeting.password }}<br />
                          </template>
                          <template v-else>
                            <p v-if="seminar.seminarConfig.confirmed === false || seminar.seminarConfig.cancelled">
                              Das Seminar muss
                              <template v-if="!seminar.seminarConfig.enabled"> aktiviert und </template>
                              bestätigt sein, damit die externen Meeting-Infos angezeigt werden können.
                            </p>
                          </template>
                        </template>
                        <template v-else>
                          Das Seminar muss
                          <template v-if="!seminar.seminarConfig.enabled"> aktiviert und </template>
                          bestätigt sein, damit der Seminar-Raum in Big Blue Button verwaltet werden kann.
                        </template>
                      </template>
                    </v-card-text>
                  </v-card>
                </template>
              </template>
              <template v-else-if="seminar.seminarConfig.presenceSeminar === true">
                <v-card
                  outlined
                  tile
                  class="fill-height"
                >
                  <v-card-title>{{ $t('Venue') }}</v-card-title>
                  <template v-if="venue">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <h3>{{ $t('Name') }}:</h3>
                            {{ venue.name }}
                            <h3>{{ $t('contactPerson') }}:</h3>
                            {{ venue.contactPerson }}
                          </v-col>
                          <v-col>
                            <h3>{{ $t('Address') }}:</h3>
                            <template v-if="venue.address.additional">{{ venue.address.additional }}<br /></template>
                            {{ venue.address.streetNr }}<br />
                            {{ venue.address.zipCode }} {{ venue.address.city }}<br />
                            {{ venue.address.country }}
                          </v-col>
                          <v-col>
                            <h3>{{ $t('Fees') }}:</h3>
                            <b>{{ $t('hotelRate') }}</b>: {{ venue.rate }} €<br />
                            <b>{{ $t('flatRate') }}</b>: {{ venue.flatRate }} €<br />
                          </v-col>
                        </v-row>
                        <template v-if="false">
                          <v-row>
                            <v-col>
                              <h3>{{ $t('venueImagePlaceHolder') }}:</h3>
                              <v-img
                                v-if="venue.image.contentUrl"
                                :src="documentEntryPoint + venue.image.contentUrl"
                                max-height="450"
                                max-width="550"
                              ></v-img>
                            </v-col>
                          </v-row>
                        </template>
                      </v-container>
                    </v-card-text>
                  </template>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      elevation="2"
                      color="primary"
                      @click="showVenue(venue)"
                    >
                      {{ $t('showVenueDetails') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <template v-if="hasRole('ROLE_READ_EXPENSE')">
            <v-divider class="mt-8" />
            <v-card
              flat
              class="mt-4"
            >
              <v-card-title>{{ $t('Budget') }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="seminar.offer"
                    class="pa-0"
                  >
                    <v-card flat>
                      <v-card-title
                        style="justify-content: center"
                        class="pa-0"
                      >
                        Verknüpftes Angebot: {{ seminar.offer.number }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="4"
                    offset="2"
                  >
                    <v-card flat>
                      <v-card-title style="justify-content: center">
                        Gewinn:
                        <v-chip
                          :color="profitColor"
                          class="ml-2"
                        >{{ profit }} &euro;</v-chip>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card flat>
                      <v-card-title style="justify-content: center">
                        Gesamtausgaben: {{ seminar.totalExpenses }}€
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col cols="12">
                <v-card
                  class="pa-1 fill-height"
                  outlined
                  tile
                >
                  <v-card-title>{{ $t('SpeakerFeeTitle') }}</v-card-title>
                  <v-card-text>
                    <div
                      v-for="speakerFee in seminar.speakerFees"
                      :key="speakerFee['speaker']"
                    >
                      <strong>{{ speakerFee.name }}</strong>: {{ speakerFee.rate }}&euro;
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <ExpenseListAdd
                  :default-item-props="{ seminar: this.item['@id'] }"
                  :expenses="expensesCombined"
                  object-type="seminar"
                  :show-message="showMessage"
                  :locked="false"
                  @updateExpenses="updateExpensesHandler"
                />
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col
              cols="6"
              v-if="hasRole('ROLE_READ_NOTE')"
            >
              <NoteListAndAdd
                :default-item-props="{ seminar: this.item['@id'] }"
                :notes="this.item.notes"
                object-type="seminar"
                :show-message="showMessage"
                @updateNotes="updateNotesHandler"
              />
            </v-col>
            <v-col cols="6">
              <DocumentListAndAdd
                :documents="seminar.files"
                @uploaded="onUploadedFiles"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <SeminarCombinedDialog
        v-model="showSeminarDialogForm"
        :seminar-item="seminar"
        :handle-updated="handleSeminarUpdated"
        :show-handle="showSeminarDialogForm"
        :allow-name="isInhouse"
        :title="seminarDialogTitle"
        @close="seminarDialogControl(true)"
        v-if="!isLoading"
      />
    </v-card>
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapFields } from 'vuex-map-fields';
  import Loading from '../../components/Loading';
  import ShowMixin from '../../mixins/ShowMixin';
  import Toolbar from '../../components/Toolbar';
  import SpeakerMixin from '@/mixins/SpeakerMixin';
  import TenantMixin from '@/mixins/TenantMixin';
  import ExpenseListAdd from '../../components/expense/ListAndAdd';
  import NoteListAndAdd from '../../components/note/ListAndAdd';
  import ExpenseMixin from '@/mixins/ExpenseMixin';
  import TopicMixin from '@/mixins/TopicMixin';
  import SeminarCombinedDialog from '@/components/seminarCombined/Dialog';
  import SeminarMixin from '@/mixins/SeminarMixin';
  import TimeSlotMixin from '@/mixins/TimeSlotMixin';
  import StatusChip from '@/components/generic/StatusChip';
  import ProfileMixin from '@/mixins/ProfileMixin';
  import ContentHtmlDialog from '@/components/generic/ContentHtmlDialog';
  import ParticipantListAndAdd from '@/components/participant/ListAndAdd';
  import DocumentListAndAdd from '@/components/document/ListAndAdd';
  import MeetingMixin from '@/mixins/MeetingMixin';
  import ModeratorDialog from '@/components/moderator/Dialog';
  import DocumentMixin from '@/mixins/DocumentMixin';
  import VenueMixin from '@/mixins/VenueMixin';
  import SignatureListDialog from '@/components/seminar/SignatureListDialog';
  import InvoiceApprovalDialog from '../../components/seminar/InvoiceApprovalDialog.vue';

  const servicePrefix = 'Seminar';

  export default {
    name: 'SeminarShow',
    servicePrefix,
    components: {
      ContentHtmlDialog,
      StatusChip,
      SeminarCombinedDialog,
      Loading,
      Toolbar,
      ExpenseListAdd,
      NoteListAndAdd,
      ParticipantListAndAdd,
      ModeratorDialog,
      DocumentListAndAdd,
      SignatureListDialog,
      InvoiceApprovalDialog
    },
    mixins: [
      ShowMixin,
      SeminarMixin,
      SpeakerMixin,
      ProfileMixin,
      TenantMixin,
      ExpenseMixin,
      TopicMixin,
      TimeSlotMixin,
      MeetingMixin,
      DocumentMixin,
      VenueMixin
    ],
    data() {
      return {
        tab: true,
        noteFormItem: {},
        showNoteDialogForm: false,
        loadingNotes: [],
        notesFilter: {
          options: {
            sortBy: [],
            descending: false,
            page: 1,
            itemsPerPage: 15
          },
          filters: {}
        },
        loadingFlags: {
          notes: false
        },
        showSeminarDialogForm: false,
        showSignatureListDialogForm: false,
        seminarDialogTitle: this.$t('EditSeminar'),
        oldSeminar: null,

        seminarContentDialog: false,
        showMeetingModeratorDialog: false,
        confirmSetDisableMessage: this.$t("confirmSetDisableMessage"),
        confirmSetEnableMessage: this.$t("confirmSetEnableMessage"),
        confirmSetCompletedMessage: this.$t("confirmSetCompletedMessage"),
        confirmSetConfirmedMessage: this.$t("confirmSetConfirmedMessage"),
        confirmSetCancelledMessage: this.$t("confirmSetCancelledMessage"),
        confirmSendAllInvitationsMessage: this.$t("confirmSendAllInvitationsMessage"),
        confirmSendSpeakerInvitationsMessage: this.$t("confirmSendSpeakerInvitationsMessage"),
        confirmBlankUpdateMessage: this.$t("confirmBlankUpdateMessage"),
        confirmDeactivateAutoInvoice: this.$t("confirmDeactivateAutoInvoice"),
        confirmSendParticipantConfirmationsMessage: this.$t(
          "confirmSendParticipantConfirmationsMessage",
        ),
        confirmSendSpeakerCertificatesMessage: this.$t(
          "confirmSendSpeakerCertificatesMessage",
        ),
        confirmSendParticipantsInvoiceMessage: this.$t(
          "confirmSendParticipantsInvoiceMessage",
        ),
        confirmPrepareInvoiceForNumberOfParticipantsMessage: this.$t(
          "confirmPrepareInvoiceForNumberOfParticipantsMessage"
        ),
        showInvoiceApprovalDialog: false,
        isForceLoading: false
      };
    },
    computed: {
      ...mapFields('seminar', {
        isSeminarLoading: 'isLoading',
        seminarUpdated: 'updated',
        seminarHandled: 'handled',
        seminarActionHandled: 'handleAction'
      }),
      ...mapGetters('seminar', { find: 'find' }),
      profit() {
        return Math.round((this.item.totalEarnings - this.item.totalExpenses) * 100) / 100;
      },
      profitColor() {
        return this.profit > 0 ? 'green' : 'red';
      },
      topics() {
        return this.resolveTopics(this.item.topics);
      },
      isFaoEnabled() {
        return this.item.faoTrainingEnabled ?? false;
      },
      isNoOnlineSeminar() {
        return this.item.noOnline ?? false;
      },
      isDeleteEnabled() {
        return this.hasRole('ROLE_DELETE_SEMINAR') && !this.seminar?.completed;
      },
      isEditEnabled() {
        return this.hasRole('ROLE_UPDATE_SEMINAR');
      },

      isLoading() {
        return this.isSeminarLoading || this.isForceLoading;
      },

      seminar() {
        return this.item;
      },
      venue() {
        if (typeof this.item === 'undefined' || this.item === null || this.item.venue === null) {
          return null;
        }
        return this.resolveVenue(this.item.venue);
      },
      timeSlot() {
        if (this.item && (typeof this.item.timeSlot === 'object' && this.item.timeSlot.start)) {
          return this.item.timeSlot;
        }
        if (this.item && (typeof this.item.timeSlot !== 'object')) {
          return this.resolveTimeSlot(this.item.timeSlot);
        }
        return null;
      },
      speaker() {
        if (this.item) {
          return this.speakerById(this.item.speaker);
        }
        return null;
      },
      coSpeaker() {
        if (this.item) {
          return this.speakerById(this.item.coSpeaker);
        }
        return null;
      },
      speakerProfile() {
        if (this.speaker) {
          return this.resolveProfile(this.speaker.profile);
        }
        return null;
      },
      coSpeakerProfile() {
        if (this.coSpeaker) {
          return this.resolveProfile(this.coSpeaker.profile);
        }
        return null;
      },
      isPublicBookable() {
        return this.currentSeminarSettings['publicBookable'];
      },
      tenantHoursLabel() {
        return this.currentSeminarSettings['hoursLabel'] === 'hours' ? 'timeHours' : 'teachingUnits';
      },
      meeting() {
        if (this.item) {
          return this.item.meeting ? this.resolveMeeting(this.item.meeting) : null;
        }
        return null;
      },
      expensesCombined() {
        if (this.seminar) {
          let expensesSeminar = this.seminar.expenses ?? [];
          return expensesSeminar
        }
        return null;
      }
    },
    methods: {
      log(data) {
        console.log(data);
      },
      ...mapActions('seminar', {
        deleteItem: 'del',
        reset: 'reset',
        retrieve: 'load',
        updateSeminar: 'update',
        actionSeminar: 'action'
      }),
      reloadSeminar() {
        // re-fetch seminar
        return this.retrieve(decodeURIComponent(this.item['@id']));
      },
      seminarDialogControl(close = false) {
        this.oldSeminar = this.item;
        this.showSeminarDialogForm = !close;
      },
      signatureListDialogControl(close = false) {
        this.showSignatureListDialogForm = !close;
      },
      inviteAllParticipants(item) {
        this.actionSeminar({ item, action: 'invite' });
      },
      inviteSpeakers() {
        this.meetingActions({
          item: this.meeting ? this.meeting : this.seminar.externalMeeting,
          action: 'invite-speakers',
          params: [{ key: 'force', value: 1 }]
        });
      },
      updateNotesHandler(updatedNotes) {
        this.item.notes = updatedNotes;
      },
      updateExpensesHandler(updatedExpenses) {
        this.item.expenses = updatedExpenses;
        this.reloadSeminar();
      },
      copy() {
        this.actionSeminar({ item: this.item, action: 'copy' });
      },
      generateParticipantList() {
        this.downloadDocument(`${this.seminar['@id']}/generateParticipantList`, 'Teilnehmerliste.pdf', true);
      },
      generateParticipantListCsv() {
        this.downloadDocument(`${this.seminar['@id']}/generateParticipantListCsv`, 'Teilnehmerliste.csv', true);
      },
      generateParticipantNameCardListCsv() {
        this.downloadDocument(
          `${this.seminar['@id']}/generateParticipantNameCardListCsv`,
          'Namenskarten.csv',
          true
        );
      },
      generateParticipantEmailListCsv() {
        this.downloadDocument(`${this.seminar['@id']}/generateParticipantEmailListCsv`, 'Emailliste.csv', true);
      },
      async handleSeminarUpdated() {
        await this.reloadSeminar();
        this.seminarDialogControl(true);
      },
      updateParticipantsHandler(updatedParticipants) {
        this.seminar.participants = updatedParticipants;
        this.reloadSeminar();
      },
      startMeeting() {
        this.meetingActions({ item: this.meeting, action: 'start' });
      },
      refreshMeetingRoom() {
        this.retrieveMeeting(decodeURIComponent(this.meeting['@id']));
      },
      meetingModeratorDialogControl(close = false) {
        this.showMeetingModeratorDialog = !close;
      },
      previewParticipantConfirmation(item) {
        this.downloadDocument(
          `/participants/confirmation/preview/${item['@id'].replace('/seminars/', '')}`,
          'preview.pdf',
          true
        );
      },

      confirmSetCompleted() {
        this.$confirm(this.confirmSetCompletedMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then(async (response) => {
          if (!response) {
            return;
          }
          this.isForceLoading = true;
          this.updateSeminar({
            '@id': this.item['@id'],
            seminarConfig: { '@id': this.item.seminarConfig['@id'], '@editedObject': true, completed: true }
          });
          await this.reloadSeminarData();
          this.isForceLoading = false;
          this.showMessage('Seminar abgeschlossen.');
        });
      },
      confirmSendAllInvitations() {
        this.$confirm(this.confirmSendAllInvitationsMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.inviteAllParticipants(this.seminar);
        });
      },
      confirmSendSpeakerInvitations() {
        this.$confirm(this.confirmSendSpeakerInvitationsMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.inviteSpeakers();
        });
      },
      confirmSetEnable() {
        this.$confirm(this.confirmSetEnableMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.updateSeminar({
            '@id': this.item['@id'],
            seminarConfig: { '@id': this.item.seminarConfig['@id'], '@editedObject': true, enabled: true }
          });
        });
      },
      confirmSetDisable() {
        this.$confirm(this.confirmSetDisableMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.updateSeminar({
            '@id': this.item['@id'],
            seminarConfig: { '@id': this.item.seminarConfig['@id'], '@editedObject': true, enabled: false }
          });
        });
      },
      confirmSetConfirmed() {
        this.$confirm(this.confirmSetConfirmedMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then(async (response) => {
          if (!response) {
            return;
          }
          this.updateSeminar({
            '@id': this.item['@id'],
            seminarConfig: { '@id': this.item.seminarConfig['@id'], '@editedObject': true, confirmed: true }
          });
          await this.reloadSeminarData();
          this.showMessage('Seminar Bestätigt.');
        });
      },
      confirmSetCancelled() {
        this.$confirm(this.confirmSetCancelledMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then(async (response) => {
          if (!response) {
            return;
          }
          this.updateSeminar({
            '@id': this.item['@id'],
            seminarConfig: { '@id': this.item.seminarConfig['@id'], '@editedObject': true, cancelled: true }
          });
          this.showMessage('Seminar Storniert.');
        });
      },
      confirmSendParticipantConfirmations() {
        this.$confirm(this.confirmSendParticipantConfirmationsMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then(async (response) => {
          if (!response) {
            return;
          }
          await this.actionSeminar({
            item: this.seminar,
            action: 'participant_confirmation_send'
          });
          this.isForceLoading = true;
          await this.reloadSeminarData();
          this.isForceLoading = false;
          this.showMessage('Teilnahmebestätigungen versendet.');
        });
      },
      confirmSendSpeakerCertificates() {
        this.$confirm(this.confirmSendSpeakerCertificatesMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then(async (response) => {
          if (!response) {
            return;
          }
          await this.actionSeminar({
            item: this.seminar,
            action: 'speaker_certificates_send'
          });
          this.showMessage('Referentenbescheinigung versendet.');
        });
      },
      confirmGenerateInvoiceParticipant() {
        this.$confirm(this.confirmPrepareInvoiceForNumberOfParticipantsMessage, {
          buttonTrueText: this.$t("Yes"),
          buttonFalseText: this.$t("No"),
        }).then(async (response) => {
          if (!response) {
            return;
          }
          await this.actionSeminar({
            item: this.seminar,
            action: "prepare_invoice_per_participant",
          });
        });
      },
      confirmSendInvoices() {
        this.$confirm(this.confirmSendParticipantsInvoiceMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then(async (response) => {
          if (!response) {
            return;
          }
          await this.actionSeminar({
            item: this.seminar,
            action: 'generateAndSendParticipantsInvoice',
            dtoResponse: true
          });
          this.showMessage('Rechnungen versendet.');
        });
      },
      blankUpdate() {
        this.$confirm(this.confirmBlankUpdateMessage, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.updateSeminar({
            ['@id']: this.seminar['@id']
          });
        });
      },
      onUploadedFiles() {
        this.updateSeminar({
          ['@id']: this.seminar['@id'],
          files: this.seminar.files
        });
        this.showMessage(this.$t('FileAdded'));
      },
      showVenue(item) {
        this.$router.push({
          name: 'VenueUpdate',
          params: { id: item['@id'] }
        });
      },
      participantConfirmationsPackage() {
        this.downloadDocument(
          `${this.seminar['@id']}/generate_participants_confirmation_package`,
          `pcp_${this.seminar.number}.zip`,
          true
        );
      },
      invoiceApprovalDialogControl(close = false) {
        this.showInvoiceApprovalDialog = !close;
      },
      deactivateAutoInvoice() {
        this.$confirm(this.confirmDeactivateAutoInvoice, {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.updateSeminar({
            '@id': this.item['@id'],
            seminarConfig: { '@id': this.item.seminarConfig['@id'], '@editedObject': true, autoInvoice: false }
          });
        });
      },
      async reloadSeminarData() {
        await this.reloadSeminar();
        await this.$refs.participantListAndAdd.refreshParticipantData();
      }
    },
    watch: {
      seminarHandled() {
        if (this.seminarActionHandled === 'copy') {
          this.showMessage(this.$t('SeminarCopied'));
          this.$router.push({
            name: 'SeminarShow',
            params: { id: this.seminarHandled['@id'] }
          });
          this.seminarActionHandled = null;
          this.seminarHandled = null;
        }

        if (this.seminarActionHandled === "prepare_invoice_per_participant") {
          // redirect to invoice edit page
          this.$router.push({
            name: `InvoiceUpdate`,
            params: { id: this.seminarHandled["@id"] }
          });
          this.seminarActionHandled = null;
          this.seminarHandled = null;
        }
        if (this.seminarActionHandled === 'generateAndSendParticipantsInvoice') {
          this.showMessage(this.$t('InvoicesSentToParticipants'));
          this.seminarActionHandled = null;
          this.seminarHandled = null;
        }
      },
    },
  };
</script>
